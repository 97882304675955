<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<header v-if="!isLoading && hall">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted, mode: 'by-halls' } }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
			<div class="hall-image-container">
				<img :src="hall.mainImageUrl" alt="">
			</div>
		</header>
		
		<main v-if="!isLoading && hall">
			<div v-if="hall.locale" class="hall">
				<div class="floor-number">
					<span class="floor-number-text">{{ $i18n.t('floor') }}</span>
					<span class="floor-number-number">{{ hall.locale.floor }}</span>
				</div>
				<div class="hall-info">
					<p v-if="hall.locale.number" class="hall-number">{{ $i18n.t('hall').toUpperCase() }} {{ hall.locale.number }}</p>
					<p class="hall-name">{{ hall.locale.name }}</p>
					<p class="hall-extra-info">{{ hall.locale.extraInfo }}</p>
				</div>
			</div>
			
			<ArtworkGrid :artworks="artworks"
			             :style="cssVars"
			             class="artworks"
			             @navigate="goToArtwork"/>
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button class="button-gray" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import MuseumsAPI from "@/api/MuseumsAPI";
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import ArtworkGrid from "@/components/ArtworkGrid";

export default {
	name: "ByHallsHall",
	
	components: {
		Loading,
		ArtworkGrid,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idHall: this.$route.params.idHall || "0"
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			hall: state => state.hall.hall,
			hallError: state => state.hall.hallError,
			hallNumber: state => state.hall.hallNumber,
			visitv2: state => state.app.visitv2,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('hall');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			const HALL_INFO_HEIGHT = 120;
			
			return {
				'--hall-image': `url(${this.hall?.mainImageUrl})`,
				'--hall-info-height': `${HALL_INFO_HEIGHT}px`,
			};
		},
		
		artworks() {
			return this.hall?.artworkImages;
		}
	},
	
	created() {
		debug.open("By Halls - Hall");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'By Halls - Options', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(async () => {
					await this.getHall(this.idHall);
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('hall', ['getHall']),
		
		async goBack() {
      if (this.isNetworkOnline){
        MuseumsAPI.updateVisitMuseumV2(this.visitv2, mutationTypes.SET_VISITV2, this.$store);
      }
			this.$router.push({
				name: 'By Halls - Options',
				params: {
					idMuseum: this.encrypted,
				},
				query: {
					hall: this.idHall
				}
			});
		},
		
		goToArtwork(idArtwork) {
			this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, {
				name: 'By Halls - Hall',
				params: { idMuseum: this.encrypted, idHall: this.idHall}
			});
			
			this.$router.push({
				name: 'By Halls - Artwork',
				params: {
					idMuseum: this.encrypted,
					idHall: this.idHall,
					idArtwork: idArtwork
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors.scss';

body {
	background-color: $by-halls-dark-gray;
}

.content {
	--map-height: calc(28vh + 30px); //The extra pixels are to avoid the logo overlapping the map
	--footer-height: 90px;
	height: 100vh;
	background-color: $by-halls-dark-gray;
	overflow: hidden;
	
	header {
		position: relative;
		
		&:after {
			content: "";
			position: fixed;
			z-index: 10;
			top: 0;
			width: 100%;
			height: var(--map-height);
			background-image:
				linear-gradient(transparent 85%, rgba(0, 0, 0, 0.7)),
				linear-gradient(rgba(0, 0, 0, 0.7) -90%, transparent);
		}
		
		.logo {
			position: absolute;
			top: 0;
			left: 30px;
			height: 60px;
			display: flex;
			align-items: center;
			z-index: 100;
			
			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}
		
		.hall-image-container {
			height: var(--map-height);
			padding-top: 50px;
			width: 100%;
			
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
	
	main {
		padding: 0 30px;
		height: 100%;
		
		.hall {
			width: 100%;
			min-height: var(--hall-info-height);
			color: white;
			display: flex;
			align-items: center;
			border-bottom: 2px solid white;
			margin-bottom: 15px;
			position: relative;
			
			&::after {
				content: '';
				position: absolute;
				z-index: 10;
				bottom: -45px;
				width: 100%;
				height: 30px;
				background: linear-gradient($by-halls-dark-gray, rgba($by-halls-dark-gray, 0)); /* transparent keyword is broken in Safari */
			}
			
			.floor-number {
				background-color: $by-halls-primary;
				color: $by-halls-gray;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				min-height: 80px;
				height: 80px;
				min-width: 80px;
				width: 80px;
				z-index: 10;
				
				.floor-number-text {
					text-transform: uppercase;
					margin-bottom: 0;
					font-size: 14px;
					margin-top: 10px;
					font-weight: 600;
				}
				
				.floor-number-number {
					margin-top: -10px;
					margin-bottom: -10px;
					font-size: 48px;
					font-weight: 900;
				}
			}
			
			.hall-info {
				margin-left: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;
				align-self: flex-end;
				margin-bottom: 20px;
				text-align: right;
				
				.hall-number {
					margin-top: 15px;
					font-weight: 300;
					color: $by-halls-primary;
				}
				
				.hall-name {
					font-size: 24px;
					font-weight: bold;
					color: $by-halls-primary;
				}
				
				.hall-extra-info {
				
				}
			}
		}
		
		.artworks {
			padding-top: 10px;
			overflow-y: auto;
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		z-index: 10;
		
		&::before {
			content: '';
			position: absolute;
			top: -30px;
			width: 100%;
			height: 31px;
			background: linear-gradient(rgba($by-halls-dark-gray, 0%), $by-halls-dark-gray); /* transparent keyword is broken in Safari */
		}
		
		.footer-buttons {
			width: 100%;
			height: var(--footer-height);
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-gray {
					background-color: $by-halls-gray;
					color: $by-halls-primary;
					
					display: flex;
					justify-content: center;
					align-items: center;
					
					span {
						font-weight: bold;
						font-size: 32px;
					}
				}
			}
		}
	}
}
</style>
