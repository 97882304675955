<template>
	<div id="main" class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<header v-if="!isLoading && hall">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted, mode: 'by-halls' } }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
			<h2 v-if="artwork.locale">{{ artwork.locale.name }}</h2>
			<div class="hall-image-container"/>
		</header>
		
		<main v-if="!isLoading && hall">
			<div class="artwork-audio">
				<audio-player v-if="artwork.locale.mainAudioUrl"
				              ref="audioPlayer"
				              :key="artwork.locale.mainAudioUrl"
				              class="audio-player"
				              :file="artwork.locale.mainAudioUrl"
							  :additionalInformation="getAdditionalInformation()" />
			</div>
			
			<div class="scroll-area">
				<div v-if="artwork.extraResources && artwork.extraResources.length" class="artwork-resources">
					<div v-for="resource in artwork.extraResources" :key="resource.idResource" class="resource" @click="openResource(resource)">
						<div class="icon-container">
							<i class="mm-icon mm-icon-add"/>
						</div>
						<span class="resource-text">{{ resource.title }}</span>
					</div>
				</div>
				
				<ArtworkGrid :artworks="artworks"
				             :id-artwork="idArtwork"
				             :style="cssVars"
				             @navigate="goToArtwork">
					<div v-if="!showMoreArtworks" class="scroll-area-overlay" @click="showMoreArtworks = true">
						{{ $t('more_artworks') }}
					</div>
				</ArtworkGrid>
			</div>
		</main>
		
		<aside v-if="openedResource" class="modal">
			<div class="modal-content">
				<h2 :class="{ 'small': smallTitle }">{{ openedResource.title }}</h2>
				
				<div v-if="openedResource.resourceType === 'TEXT'" class="content">
					{{ openedResource.locale.content }}
				</div>
				
				<div v-else-if="openedResource.resourceType === 'VIDEO'" class="content">
					<video controls autoplay playsinline class="resource-video">
						<source :src="openedResource.locale.url" type="video/mp4">
					</video>
				</div>
				
				<div v-else-if="openedResource.resourceType === 'AUDIO'" class="content">
					<audio-player v-if="openedResource.locale.url"
					              :key="openedResource.locale.url"
					              class="audio-player"
					              :file="openedResource.locale.url"/>
				</div>
				
				<div v-else-if="openedResource.resourceType === 'IMAGE'" class="content">
					<img class="resource-image" :src="openedResource.url" alt="">
				</div>
				
				<div class="footer">
					<i v-if="openedResource.resourceType === 'TEXT'" class="mm-icon mm-icon-text-size" @click="changeTextSize()"/>
					<div class="icon-container">
						<i class="mm-icon mm-icon-close" @click="openedResource = null"/>
					</div>
				</div>
			</div>
		</aside>
		
		<footer>
			<div class="footer-buttons">
				<button class="button-gray" @click="goBack()">
					<i :style="backGroundColor" class="mm-icon mm-icon-arrow-back big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";
import ArtworkVisit from "@/models/artworkVisit";
import MuseumsAPI from "@/api/MuseumsAPI";
import firebase from "firebase/app";
import ArtworkGrid from "@/components/ArtworkGrid";

export default {
	name: "ByHallsArtwork",
	
	components: {
		ArtworkGrid,
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idHall: this.$route.params.idHall || "0",
			idArtwork: this.$route.params.idArtwork || "0",
			artworkVisit: new ArtworkVisit(),
			openedResource: null,
			smallTitle: false,
			resourceTextSize: 18,
			showMoreArtworks: false
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			artwork: state => state.artwork.artwork,
			artworkError: state => state.artwork.artworkError,
			visit: state => state.app.visit,
			hall: state => state.hall.hall,
			hallError: state => state.hall.hallError,
			studyQuestions: state => state.studyQuestion.studyQuestions,
			studyQuestionsError: state => state.studyQuestion.studyQuestionsError,
			visitv2: state => state.app.visitv2,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('my_favourites_artworks');
			if(this.artwork?.locale?.name) {
				locationName = this.artwork.locale.name;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			const FOOTER_HEIGHT = 100;
			const RESOURCES_MARGIN = 15;
			
			let resourcesHeight = 0;
			let numberOfResources = this.artwork?.extraResources?.length;
			if(numberOfResources > 0) {
				if(numberOfResources === 1) {
					resourcesHeight += 60;
				} else if(numberOfResources === 2) {
					resourcesHeight += 110;
				} else {
					resourcesHeight += 130;
				}
			}
			
			let audioHeight = 20;
			if(this.artwork?.locale?.mainAudioUrl) {
				audioHeight += 90;
			}
			
			let freeSpace = FOOTER_HEIGHT + audioHeight + resourcesHeight;
			let freeScrollAreaSpace = FOOTER_HEIGHT + audioHeight - RESOURCES_MARGIN;
			
			return {
				'--hall-image': `url(${this.artwork?.mainImageUrl})`,
				'--artworks-height': `calc(70vh - ${freeSpace}px)`,
				'--scroll-area-height': `calc(70vh - ${freeScrollAreaSpace}px)`,
				'--resources-height': `${resourcesHeight}px`,
				'--scroll-area-overflow': this.showMoreArtworks ? 'scroll' : 'hidden',
				'--resource-text-size': `${this.resourceTextSize}px`,
			};
		},
		
		artworks() {
			return this.hall?.artworkImages;
		},
		backGroundColor(){
				return{
				'color': this.museum.code ==='MPICASSOM' && this.keyboardCustomization ? this.keyboardCustomization.mainButtonsColor : 'black',
			};

		},
	},
	
	created() {
		debug.open("By Halls - Artwork");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.findArtwork(this.idArtwork);
					await this.getHall(this.idHall);
					this.getStudyQuestions(this.idArtwork);
				})
				.finally(() => {
          if (this.isNetworkOnline){
            this.setArtworkVisit();
          }
					if(firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('artwork', ['findArtwork']),
		...mapActions('hall', ['getHall']),
		...mapActions('studyQuestion', ['getStudyQuestions']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		createNewArtworkVisit() {
			localStorage.removeItem('current-artwork-visit');
			this.artworkVisit = new ArtworkVisit(this.idArtwork, null, new Date(), new Date());
			this.$store.commit('app/' + mutationTypes.ADD_ARTWORK_VISIT, this.artworkVisit);
		},
		
		setArtworkVisit() {
			debug.open("setArtworkVisit");
			
			let currentArtworkVisit = localStorage.getItem('current-artwork-visit');
			try {
				currentArtworkVisit = JSON.parse(currentArtworkVisit);
				if(currentArtworkVisit.idArtwork === this.idArtwork) {
					debug.log("Continuing with the visit", currentArtworkVisit);
					this.artworkVisit.parseJSON(currentArtworkVisit);
				} else {
					debug.log("There is a visit ongoing, but it is not for this artwork, finishing it and creating a new one...");
					this.createNewArtworkVisit();
				}
			} catch(e) {
				debug.log("There is no visit ongoing, creating one...");
				this.createNewArtworkVisit();
			}
			
			localStorage.setItem('current-artwork-visit', JSON.stringify(this.artworkVisit));
			
			debug.close();
		},
		
		openResource(resource) {
			this.openedResource = resource;
			if(this.$refs.audioPlayer) {
				this.$refs.audioPlayer.playing = false;
			}
			const containerWidth = window.innerWidth - 140;
			const PIXELS_PER_CHARACTER = 15;
			const charactersInWidth = containerWidth / PIXELS_PER_CHARACTER;
			this.smallTitle = resource.title?.split(' ').some(word => word.length > charactersInWidth);
		},
		
		changeTextSize() {
			if(this.resourceTextSize >= 24) {
				this.resourceTextSize = 14;
			} else {
				this.resourceTextSize += 2;
			}
		},
		
		async goBack() {
			setFullScreen();
			
			//Register the visit to this artwork
			debug.open("Registering visit");
			this.$store.commit('app/' + mutationTypes.UPDATE_LAST_ARTWORK_VISIT);
			this.$store.commit('app/' + mutationTypes.UPDATE_VISIT_END_DATE, new Date());
			const currentTime = document.getElementsByClassName("player")[0]?.firstChild.currentTime || 0;
      if (currentTime >= 1 && this.isNetworkOnline) {
        MuseumsAPI.updateVisitArtworkV2(this.visitv2.idVisit, this.idArtwork, currentTime);
      }
      if(this.isNetworkOnline){
        MuseumsAPI.updateVisitMuseumV2(this.visitv2, mutationTypes.SET_VISITV2, this.$store);
      }
      if(this.from === "RouteList" && this.isNetworkOnline){
        MuseumsAPI.updateVisitRouteListV2(this.visitv2.idVisit, this.route.idRoute);
      }
      if(this.isNetworkOnline){
        MuseumsAPI.registerVisit(this.idMuseum, {...this.visit, languageCode: this.visitv2?.languageCode || this.visit.languageCode})
            .then((response) => {
              this.$store.commit('app/' + mutationTypes.UPDATE_ID_VISIT, response.data?.data?.IdVisit, {root: true});
            })
            .catch((e) => {
              if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
              localStorage.setItem('visit', JSON.stringify(this.visit));
            })
            .finally(() => {
              this.$store.commit('app/' + mutationTypes.SET_VISIT, this.visit, {root: true});
              this.$store.commit('app/' + mutationTypes.DELETE_ARTWORK_VISIT);
              debug.close();
            });
      }
      debug.log("Finished visit");
      //Attach surveys after a specific artwork visit
      let queryFrom = null;
      if (this.$router.history?.current?.query?.from) {
        queryFrom = this.$router.history.current.query.from;
      }

			let hasStudyQuestions = Object.values(this.studyQuestions).some(q => q.idArtwork === this.idArtwork);
			if(hasStudyQuestions) {
				await this.$router.push({
					name: 'StudyQuestionArtwork',
					params: { idMuseum: this.encrypted, idArtwork: this.idArtwork },
					...(queryFrom && { query: { from: queryFrom }})
				});
			} else {
				await this.$router.push({
					name: 'By Halls - Hall',
					params: { idMuseum: this.encrypted, idHall: this.idHall },
					query: {
						artwork: this.idArtwork,
						...(queryFrom && { from: queryFrom })
					}
				});
			}
		},
		
		goToArtwork(idArtwork) {
			this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, {
				name: 'By Halls - Hall',
				params: { idMuseum: this.encrypted, idHall: this.idHall}
			});
			
			this.$router.push({
				name: 'By Halls - Artwork',
				params: {
					idMuseum: this.encrypted,
					idHall: this.idHall,
					idArtwork: idArtwork
				}
			});
		},
		
		getAdditionalInformation() {
			return this.artwork.otherResources.filter(r => r.isAdditionalInformation);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors.scss';

body {
	background-color: $by-halls-dark-gray;
}

.content {
	--map-height: 30vh;
	--hall-info-height: 125px;
	--footer-height: 90px;
	height: 100vh;
	background-color: $by-halls-dark-gray;
	overflow: hidden;
	position: relative;
	
	header {
		position: relative;
		
		.logo {
			position: absolute;
			top: 0;
			left: 30px;
			height: 60px;
			display: flex;
			align-items: center;
			
			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}
		
		.hall-image-container {
			height: var(--map-height);
			width: 100%;
			background-image:
				linear-gradient(transparent 80%, rgba(0, 0, 0, 0.5)),
				linear-gradient(rgba(0, 0, 0, 0.5) 2%, transparent),
				var(--hall-image);
			background-size: cover;
			background-position: center center;
		}
		
		h2 {
			position: absolute;
			bottom: 10px;
			right: 10px;
			text-align: right;
			color: $by-halls-primary;
			font-size: 24px;
		}
	}
	
	main {
		padding: 0 30px;
		height: 100%;
		
		.artwork-audio {
			padding: 10px 0;
		}
		
		.scroll-area-overlay {
			position: absolute;
			height: calc(var(--scroll-area-height) - var(--resources-height));
			width: calc(100% + 30px);
			left: -10px;
			right: -10px;
			top: -10px;
			padding: 20px;
			text-align: center;
			z-index: 10;
			background-color: $by-halls-dark-gray;
			opacity: .975;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			color: $by-halls-primary;
			font-weight: bold;
			font-size: 16px;
		}
		
		.scroll-area {
			overflow-y: var(--scroll-area-overflow);
			height: var(--scroll-area-height);
			position: relative;
			
			&::-webkit-scrollbar {
				display: none;
			}
			
			.artwork-resources {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				margin-bottom: 20px;
				width: 100%;
				overflow-y: auto;
				overflow-x: hidden;
				
				.resource {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					
					.icon-container {
						background-color: $by-halls-primary;
						border-radius: 50%;
						height: 30px;
						width: 32px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 10px;
						
						.mm-icon {
							font-size: 32px;
							margin-top: -3px;
							color: $by-halls-dark-gray;
						}
					}
					
					.resource-text {
						color: $by-halls-primary;
						font-size: 20px;
					}
				}
			}
		}
	}
	
	.modal {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px;
		height: 100%;
		width: 100%;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.6);
		
		.modal-content {
			width: 100%;
			height: 100%;
			box-shadow: 0 0 10px white, 0 0 12px rgba($by-halls-primary, 50%);
			background-color: $by-halls-dark-gray;
			padding: 30px 30px 75px;
			position: relative;
			display: flex;
			flex-direction: column;
			
			h2 {
				width: 100%;
				color: $by-halls-primary;
				font-size: 24px;
				margin-bottom: 20px;
				
				&.small {
					font-size: 18px;
				}
			}
			
			.content {
				height: calc(100% - 90px);
				overflow-y: auto;
				font-size: var(--resource-text-size);
				color: white;
				
				.resource-image {
					width: 100%;
					height: auto;
				}
				
				.resource-video {
					width: 100%;
					height: auto;
					max-height: 100%;
				}
			}
			
			.footer {
				position: absolute;
				right: 10px;
				left: 10px;
				bottom: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				.mm-icon {
					font-size: 45px;
					color: white;
				}
				
				.icon-container {
					width: 50px;
					height: 50px;
					background-color: $by-halls-primary;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: auto;
					
					.mm-icon {
						font-size: 45px;
						color: $by-halls-dark-gray;
					}
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		z-index: 10;
		
		&::before {
			content: '';
			position: absolute;
			top: -30px;
			width: 100%;
			height: 31px;
			background: linear-gradient(rgba($by-halls-dark-gray, 0%), $by-halls-dark-gray); /* transparent keyword is broken in Safari */
		}
		
		.footer-buttons {
			width: 100%;
			height: var(--footer-height);
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-gray {
					background-color: $by-halls-gray;
					color: $by-halls-primary;
					
					display: flex;
					justify-content: center;
					align-items: center;
					
					span {
						font-weight: bold;
						font-size: 32px;
					}
				}
			}
		}
	}
}
</style>
