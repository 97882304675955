<template>
  <div v-if="artworks" id="artworks-grid" class="artworks">
    <div
      v-for="(artwork, index) in artworks"
      :id="'artwork-' + artwork.idArtwork"
      :key="artwork.idArtwork"
      :class="{
        current: idArtwork ? +artwork.idArtwork === +idArtwork : index === 0,
      }"
      class="artwork"
      @click="
        createNewArtworkVisit(artwork.idArtwork);
        $emit('navigate', artwork.idArtwork);
      "
    >
      <div v-if="artwork.isOutstanding" class="outstanding-badge">
        <i class="mm-icon mm-icon-star" />
      </div>
      <div class="artwork-image-container">
        <img
          class="artwork-image"
          :src="getBlobImageUrl(artwork.mainImageUrl)"
          alt=""
        >
      </div>
      <div class="artwork-info">
        <span v-if="artwork.showCode" class="artwork-code">{{
          artwork.artworkCode
        }}</span>
        <span class="artwork-name">{{ artwork.artworkName }}</span>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import MuseumsAPI from "@/api/MuseumsAPI";
import { mapState } from "vuex";

export default {
  name: "ArtworkGrid",

  props: {
    artworks: {
      type: Array,
    },
    idArtwork: {
      type: [Number, String, Blob],
      default: null,
    },
  },

  data() {
    return {
      navigate: true,
    };
  },

	computed: {
		...mapState({
			visitv2: state => state.app.visitv2,
		}),
	},
	
	mounted() {
		if(this.navigate && this.artworks && this.$route.query.artwork) {
			this.navigate = false;
			const element = document.getElementById('artwork-' + this.$route.query.artwork);
			document.getElementById('artworks-grid').scrollTop = element.offsetTop - 25;
		}
		this.artworks.forEach(artwork => {
			const element = document.getElementById('artwork-' + artwork.idArtwork)?.querySelector('.artwork-name');
			const containerWidth = element?.parentElement?.offsetWidth;
			const PIXELS_PER_CHARACTER = 10;
			const charactersInWidth = containerWidth / PIXELS_PER_CHARACTER;
			element.classList.toggle('small', artwork.artworkName?.split(' ').some(word => word.length > charactersInWidth));
		});
		
		const HALL_INFO_HEIGHT = 120;
		const FOOTER_HEIGHT = 90;
		const hallHeight = document.querySelector('.hall')?.offsetHeight;
		const height = `calc(84vh - ${HALL_INFO_HEIGHT}px - ${hallHeight}px - ${FOOTER_HEIGHT}px)`;
		document.getElementById('artworks-grid').style.maxHeight = height;
	},
	methods: {		
		async createNewArtworkVisit(idArtwork) {
      if (this.isNetworkOnline){
        MuseumsAPI.registerVisitArtworkV2(this.visitv2.idVisit, idArtwork);
      }
		},

    getBlobImageUrl(mainImageUrl) {
      if (mainImageUrl instanceof Blob){
        return URL.createObjectURL(mainImageUrl);
      }

      return mainImageUrl;
    },
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors';

.artworks {
	position: relative; // This is needed for scrolling to an artwork
	width: 100%;
	padding-bottom: 20px;
	
	display: flex;
	flex-wrap: wrap;
	
	.artwork {
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-bottom: 10px;
		position: relative;
		
		&.current .artwork-image-container {
			box-shadow: 0 0 7px white, 0 0 7px rgba($by-halls-primary, 50%);
		}
		
		.outstanding-badge {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background-color: $by-halls-primary;
			
			position: absolute;
			top: 12px;
			right: 12px;
			z-index: 1;
			
			.mm-icon {
				font-size: 24px;
				color: $by-halls-dark-gray;
			}
		}
		
		.artwork-image-container {
			width: 100%;
			height: 110px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			margin-bottom: 10px;
			
			.artwork-image {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
		
		.artwork-info {
			.artwork-code {
				color: white;
				margin-right: 10px;
			}
			
			.artwork-name {
				color: $by-halls-primary;
				
				&.small {
					font-size: 12px;
				}
			}
		}
	}
}

</style>
