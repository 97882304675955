import GenericClass from "@/models/generic-class";

export const AccessType = {
	KEYBOARD: "KEYBOARD",
	RECOGNITION: "RECOGNITION",
};

export default class ArtworkVisit extends GenericClass {
	idArtwork; // number;
	accessType; // AccessType;
	startDate; // Date;
	endDate; // Date;

	constructor(idArtwork, accessType, startDate, endDate) {
		super();
		this.idArtwork = idArtwork;
		this.accessType = accessType;
		this.startDate = startDate;
		this.endDate = endDate;
	}
}
